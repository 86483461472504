import React from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import {graphql, Link, useStaticQuery} from "gatsby";
import ProductList from "components/ProductList/ProductList";
import ContactForm from "../../components/ContactForm";
import ContactInfo from "../../components/ContactInfo";
import logo from "../../../static/images/logo.png";
import MainNavigation from "../../components/MainNavigation/MainNavigation";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";

const IndexPage = ({location, data}) => {

    const query = useStaticQuery(graphql`
        query {
            allMdx(filter: {frontmatter: {kind: {eq: "product"}, language: {eq:"sk"}, featured: {eq:true}}}) {
                nodes {
                    id
                    frontmatter {
                        id
                        kind
                        language
                        title
                        uri
                        priceVat
                        priceNoVat
                        option
                        vat
                        unit
                        featured
                        gallery {
                            image {
                                big: childImageSharp {
                                    fluid(maxWidth: 1200) {
                                        src
                                    }
                                }
                                small: childImageSharp {
                                    fluid(maxWidth: 400) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allFile(filter: {relativeDirectory: {eq: "partners"}}) {
                edges {
                  node {
                    childImageSharp {
                      fluid(quality: 70) {
                        srcWebp
                      }
                    }
                  }
                }
            }
        }
    `);

    const products = query.allMdx.nodes;
    const locale = getLocaleFromPath(location.pathname);


  
    return (
        <Layout location={location}>
            <div className="MainContent bg">
                <section id="intro">
                    <div className="container">
                        <div className="c60p">
                            <h2>Objavte svet chutí diviny</h2>
                            <p>Divina je súčasťou nášho života od nepamäti. Poskytuje nám prirodzený zdroj mäsa, ktoré je kvalitnejšie a výživnejšie. Jednotlivé druhy zvierat žijú voľne bez zásahov človeka, a to má vplyv aj na zloženie mäsa, teda bez antibiotík a rastových hormónov. Mäso a výrobky z neho sú tak vhodné aj pre konzumentov ako sú deti a chorí ľudia.</p>
            	            <p>Mäso z diviny sa hodí na výrobu širokej škály výrobkov, ktoré pri použití osvedčených receptúr prinášajú skvelé chute a exkluzívnu kvalitu na váš stôl.</p>
                            <p>Radi by sme rozšírili povedomie o divine ako o ďalšej možnosti ako spestriť jedálniček ľudí. Našou snahou je zároveň spraviť divinu dostupnejšou pre širší okruh ľudí a to aj hotovými výrobkami, ktoré odbremenia ľudí od zdĺhavej prípravy a propagovaním receptov, ktoré usmernia ľudí pri príprave jedál z diviny.</p>
                            <p>Výrobky sú vyrábané pod stálym veterinárnym dozorom a suroviny vyberáme len z overených zdrojov.</p>
            	            <p>Sme zástancami názoru, že využívať prírodné zdroje je nevyhnutné pri zachovaní trvalej udržateľnosti.</p>
                        </div>
                        <div className="cleaner">x</div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <h2>Vybrali sme pre Vás:</h2>
                        <ProductList title="Naša ponuka" locale={locale} items={products}/>
                        <div className="buttonHolder"><Link className="button" to={"/"+locale+"/produkty"}>Všetky produkty</Link></div>
                    </div>
                </section>
                <section id="contact">
                    <div className="container">
                        <h2>Kontakt</h2>
                        <div className="c40p">
                            <ContactInfo locale={locale}/>
                        </div>
                        <div className="c60p">
                            <ContactForm title="Máte otázky?" locale={locale}/>
                        </div>
                        <div className="cleaner">&nbsp;</div>
                    </div>
                </section>
                <h2>Partneri</h2>
                <section className="partners">
                <div className="container">
                        

                        {query.allFile.edges.map((file, index) => {
                            return (
                                <div className="c30p float-right" key={`partner-${index}`}>
                                    <img src={file.node.childImageSharp.fluid.srcWebp} alt="" />
                                </div>
                            )
                            })}

                        <div className="cleaner">&nbsp;</div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default withI18n()(IndexPage);

